import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import DebounceInput from "nlib/ui/DebounceInput";
import React, { useCallback, useEffect, useState } from "react";
import useEnvVars from "hooks/useEnvVars";

const SummaryFilter = ({ disabled }) => {
  const [{ businessName: initialBusinessName = "" }, setEnvVars] = useEnvVars();

  const { uiTexts } = useSelector(getTextsData);

  const [businessName, setBusinessName] = useState(initialBusinessName);

  const handleBusinessNameInputChange = useCallback((value) => {
    setBusinessName(value);
  }, []);

  const handleBusinessNameInputComplete = useCallback((value) => {
    setEnvVars({ businessName: value || null });
  }, [setEnvVars]);

  useEffect(() => {
    setBusinessName(initialBusinessName);
  }, [initialBusinessName]);

  return (
    <div className={Css.summaryFilter}>
      <DebounceInput
        cleanable
        disabled={disabled}
        value={businessName}
        placeholder={uiTexts.searchBusiness}
        onChange={handleBusinessNameInputChange}
        onInputComplete={handleBusinessNameInputComplete} />
    </div>
  );
};

export default React.memo(SummaryFilter);
