import CommonCss from "nlib/common/common.module.scss";

import googleDrive from "assets/googleDrive.svg";

import Css from "./style.module.scss";

import { Button } from "nlib/ui";
import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback } from "react";
import UiActions from "actions/UiActions";
import classNames from "classnames";

const GoogleBackupButton = ({ className, disabled, ...restProps }) => {
  const dispatch = useDispatch();

  const { uiTexts } = useSelector(getTextsData);

  const {
    backupInfo: { accountConnected = false, accountEmail = "" } = {}
  } = useSelector(getActiveOrganization);

  const handleButtonClick = useCallback(() => {
    dispatch(UiActions.showSetupBackupWindow());
  }, [dispatch]);

  return (
    <Button
      large outline
      disabled={disabled}
      className={classNames(Css.googleBackupButton, className)}
      onClick={handleButtonClick}
      {...restProps}>
      <span>
        <img src={googleDrive} />
        <span>
          {accountEmail
            ? (accountConnected ? uiTexts.backupEnabled : uiTexts.backupDisabled)
            : uiTexts.setupGoogleDriveBackup}
        </span>
        {accountEmail && (
          <span
            className={classNames(Css.circle, accountConnected ? CommonCss.positiveText : CommonCss.negativeText)} />
        )}
      </span>
    </Button>
  );
};

export default React.memo(GoogleBackupButton);
